export const CATALOG_REQUEST = 'CATALOG_REQUEST';
export const CATALOG_REQUEST_FAILURE = 'CATALOG_REQUEST_FAILURE';
export const CATALOG_REQUEST_SUCCESS = 'CATALOG_REQUEST_SUCCESS';
export const CATALOG_REQUEST_MORE = "CATALOG_REQUEST_MORE";

export const TOP_REQUEST = 'TOP_REQUEST';
export const TOP_REQUEST_FAILURE = 'TOP_REQUEST_FAILURE';
export const TOP_REQUEST_SUCCESS = 'TOP_REQUEST_SUCCESS';
export const PRODUCT_REQUEST = "PRODUCT_REQUEST";
export const PRODUCT_REQUEST_FAILURE = "PRODUCT_REQUEST_FAILURE";
export const PRODUCT_REQUEST_SUCCESS = "PRODUCT_REQUEST_SUCCESS";
export const CAT_REQUEST = "CAT_REQUEST";
export const CAT_REQUEST_FAILURE = "CAT_REQUEST_FAILURE";
export const CAT_REQUEST_SUCCESS = "CAT_REQUEST_SUCCESS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const RESET_CART = "RESET_CART";
export const ADD_COUNT = "ADD_COUNT";
export const REMOVE_COUNT = "REMOVE_COUNT";
export const SELECT_SIZE = "SELECT_SIZE";
export const SELECT_CAT = "SELECT_CAT";

export const CHANGE_SEARCH_FIELD   = 'CHANGE_SEARCH_FIELD';
export const TOGGLE_SEARCH_FIELD = 'TOGGLE_SEARCH_FIELD';

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_REQUEST_SUCCESS = "ORDER_REQUEST_SUCCESS";
export const ORDER_REQUEST_FAILURE = "ORDER_REQUEST_FAILURE";
export const RESET_ORDER = "RESET_ORDER";