import React from 'react';
import PropTypes from 'prop-types';
import Header from "./Header";
import Footer from "./Footer";
import BodyWrapper from "./BodyWrapper";
import Banner from "./Banner";

AboutPage.propTypes = {

};

function AboutPage(props) {
    return (
        <BodyWrapper>
            <Banner/>
            <section className="top-sales">
                <h2 className="text-center">О магазине</h2>
                <p>
                    В Интернете можно встретить немало магазинов, предлагающих аксессуары. Но именно к нам хочется
                    возвращаться снова и снова.
                </p>
                <p className="h4 text-center">Мы предлагаем вам особые условия:</p>
                <ol>
                    <li>Индивидуальный подход специалиста. Когда поступает новая коллекция обуви весна-лето или
                        же коллекция обуви осень-зима – покупателям бывает трудно сориентироваться во всем
                        многообразии новинок. Наш менеджер по телефону поможет вам определиться с товарами,
                        подходящими именно вам.
                    </li>
                    <li>Мы периодически проводим распродажи как женских и мужских, так и детских моделей. Вы
                        будете приятно удивлены ценами на аксессуары в мага- зине BosaNoga.
                    </li>
                    <li>У нас всегда есть из чего выбрать. Неважно, какую категорию вы прос- матриваете:
                        осень-зима, или же весна-лето – вы всегда сможете найти ва- рианты, подходящие вам по
                        внешнему виду и цене.
                    </li>
                    <li>Мы несем ответственность за все товары.</li>
                    <li>Молодые мамы будут рады обширному ассортименту детских моделей.</li>
                </ol>
                <p> Если вы ищете место, где представлены обувные новинки от самых известных брендов, то вы
                    зашли по верному адресу. </p>
                <p>У нас представлены модели для мужчин, женщин, а также детские сапоги, босоножки, ботинки и
                    туфли. Сделав заказ в нашем интернет-магазине, вы сможете быть модным и стильным как
                    осенью-зимой, так и весной-летом. Просто наберите номер нашего телефона, и мы поможем вам
                    определиться с покупкой. </p>
            </section>
        </BodyWrapper>
    );
}

export default AboutPage;