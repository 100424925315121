import React from 'react';
import PropTypes from 'prop-types';

OrderResult.propTypes = {
    
};

function OrderResult(props) {
    return (
        <div>Заказ успешно осуществлен, ожидайте звонка оператора.</div>
    );
}

export default OrderResult;